.line-label, .line-circle, .line-path {
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
    pointer-events: none;
}

@font-face {
    font-family: 'BrandonText';
    font-style: normal;
    font-weight: 300;
    src: local('BrandonText-Regular'), url(/static/media/BrandonText-Regular.cc4e72bd.otf);
}
@font-face {
    font-family: 'BrandonText-RegularItalic';
    font-style: italic;
    font-weight: 300;
    src: local('BrandonText-RegularItalic'), url(/static/media/BrandonText-RegularItalic.c1b472c3.otf);
}
@font-face {
    font-family: 'BrandonText-Light';
    font-style: normal;
    font-weight: 200;
    src: local('BrandonText-Light'), url(/static/media/BrandonText-Light.95fd2a9c.otf);
}
@font-face {
    font-family: 'BrandonText-Thin';
    font-style: normal;
    font-weight: 200;
    src: local('BrandonText-Thin'), url(/static/media/BrandonText-Thin.ca4b8eb6.otf);
}

