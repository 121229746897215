
@font-face {
    font-family: 'BrandonText';
    font-style: normal;
    font-weight: 300;
    src: local('BrandonText-Regular'), url(./BrandonText-Regular.otf);
}
@font-face {
    font-family: 'BrandonText-RegularItalic';
    font-style: italic;
    font-weight: 300;
    src: local('BrandonText-RegularItalic'), url(./BrandonText-RegularItalic.otf);
}
@font-face {
    font-family: 'BrandonText-Light';
    font-style: normal;
    font-weight: 200;
    src: local('BrandonText-Light'), url(./BrandonText-Light.otf);
}
@font-face {
    font-family: 'BrandonText-Thin';
    font-style: normal;
    font-weight: 200;
    src: local('BrandonText-Thin'), url(./BrandonText-Thin.otf);
}
